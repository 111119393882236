import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import "./style.css";
ReactDOM.render(
  <>
  <Suspense fallback={<div id="preloader"></div>}>
    <App />
  </Suspense>
  </>,
  document.getElementById("root")
);

