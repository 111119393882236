import React, {lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const Navbar = lazy(() => import("./components/common/Navbar"))
  const Home = lazy(() => import("./components/Home/Home"))
  const Skills = lazy(() => import("./components/Skills/Skills"))
  const Resume = lazy(() => import("./components/Resume/ResumeNew"))
  const Contact = lazy(()=>import("./components/contact/Contact"))
  const ScrollToTop = lazy(()=>import("./components/common/ScrollToTop"))

  return (
    <Router>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/project" element={<Projects />} /> */}
          <Route path="/skills" element={<Skills />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </Router>
  );
}

export default App;
